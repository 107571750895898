import { Link as RouterLink } from "react-router-dom";
// @mui
import { Button, Typography, Container, Box } from "@mui/material";
// components
import Page from "src/components/Page";

export default function Page404() {
  return (
    <Page title="404 Page Not Found">
      <Container>
        <Box
          sx={{
            maxWidth: 480,
            margin: "auto",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingY: 12,
            paddingX: 0,
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>

          <Box
            component="img"
            src="/static/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
    </Page>
  );
}
