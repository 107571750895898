import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAuth } from "src/components/contexts/AuthContext";
import api from "src/services/api";
import { ObjectToQueryString } from "src/utils/uri";

// API methods - not exposed outside this class
// getPartners = Get list of all partners
const getUserPartners = async (options) => {
  const { data } = await api.get(
    `/partnerUser?${ObjectToQueryString(options)}`
  );
  return data;
};

const setUserPartnerAsDefault = async (partnerId) => {
  const { data } = await api.put(`/partnerUser/${partnerId}/setDefault`);
  return data;
};

// Custom hooks
export const useUserPartnerList = (page = 0, perPage = 10) => {
  return useQuery(["userPartnerList", { page, perPage }], () =>
    getUserPartners({ page, perPage })
  );
};

export const useSetPartnerAsDefault = () => {
  const queryClient = useQueryClient();

  return useMutation(setUserPartnerAsDefault, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries(["userPartnerList"]);
    },
  });
};

// This returns a list of all default partners, but there should be at most 1
export const useGetDefaultPartners = (
  page = 0,
  perPage = 10,
  getDefault = true
) => {
  const { auth } = useAuth();
  return useQuery(
    ["userPartnerList", { page, perPage, getDefault }],
    () => getUserPartners({ page, perPage, getDefault }),
    { enabled: auth.authenticated }
  );
  //Should the above line actually look like this?
  //return useQuery(["defaultPartner"], () => getDefaultPartner());
};
