import PropTypes from "prop-types";
// material
import { Box, Popover } from "@mui/material";
import { alpha } from "@mui/material/styles";

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default function MenuPopover({ children, sx, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      disableScrollLock={true}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: "inherit",
          ...sx,
        },
      }}
      {...other}
    >
      <Box
        component={"span"}
        className="arrow"
        sx={(theme) => ({
          [theme.breakpoints.up("sm")]: {
            top: -7,
            zIndex: 1,
            width: 12,
            right: 20,
            height: 12,
            content: "''",
            position: "absolute",
            borderRadius: "0 0 4px 0",
            transform: "rotate(-135deg)",
            background: theme.palette.background.paper,
            borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
            borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
          },
        })}
      />

      {children}
    </Popover>
  );
}
