import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";
import { useCurrentUser } from "src/hooks/user";

// ----------------------------------------------------------------------

function NavSectionListItem({ sx, ...props }) {
  return (
    <ListItemButton
      disableGutters
      {...props}
      sx={(theme) => ({
        ...theme.typography.body2,
        height: 48,
        position: "relative",
        textTransform: "capitalize",
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        ...sx,
      })}
    />
  );
}

function NavSectionIcon({ sx, children, ...props }) {
  return (
    <ListItemIcon
      sx={{
        width: 22,
        height: 22,
        color: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      {...props}
    >
      {children}
    </ListItemIcon>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const userQuery = useCurrentUser();
  const user = userQuery.data;
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, canAccess } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (canAccess && !canAccess(user)) {
    return <div />;
  }
  if (children) {
    return (
      <>
        <NavSectionListItem
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <NavSectionIcon>{icon && icon}</NavSectionIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </NavSectionListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <NavSectionListItem
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <NavSectionIcon>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </NavSectionIcon>
                  <ListItemText disableTypography primary={title} />
                </NavSectionListItem>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <NavSectionListItem
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <NavSectionIcon>{icon && icon}</NavSectionIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </NavSectionListItem>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
