import { Fragment } from "react";

// const SpinnerWrapperStyle = styled('div')(({ theme }) => ({
//     position: "absolute",
//     top: "0px",
//     right: "0px",
//     width: "100%",
//     height: "100%",
//     backgroundColor: "#eceaea",
//     zIndex: 10000000,
//     opacity: 0.4,
//     filter: "alpha(opacity=40)"
//   }));

export default function Spinner() {
  return (
    <Fragment>
      <svg
        style={{
          margin: "auto",
          background: "transparent",
          display: "block",
          shapeRendering: "auto",
        }}
        width="60"
        height="60"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <path
          fill="none"
          stroke="#009ef7"
          strokeWidth="11"
          strokeDasharray="210.40292114257812 46.18600708007813"
          d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
          strokeLinecap="butt"
          style={{
            transform: "scale(0.9500000000000001)",
            transformOrigin: "50px 50px",
          }}
        >
          <animate
            attributeName="stroke-dashoffset"
            repeatCount="indefinite"
            dur="1.7857142857142856s"
            keyTimes="0;1"
            values="0;256.58892822265625"
          ></animate>
        </path>
      </svg>
    </Fragment>
  );
}
