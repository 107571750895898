import { Box } from "@mui/material";
import MainMenuLink from "./MainMenuLink";
import { useCurrentUser } from "src/hooks/user";
import { Fragment } from "react";

export default function TopNavSection({ navConfig, sx }) {
  const userQuery = useCurrentUser();
  const user = userQuery.data;
  return (
    <Box
      sx={(theme) => ({
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        alignItems: "center",
        alignText: "center",
        justifyContent: "flex-end",
        display: "flex",
        flexGrow: 1,
        ...sx,
      })}
    >
      {navConfig.map((item) => {
        if (item.canAccess && !item.canAccess(user)) {
          return <Fragment key={item.title} />;
        }
        return (
          <MainMenuLink
            key={item.title}
            to={item.path}
            text={item.title}
            childLinks={
              item.children &&
              item.children.map((child) => {
                return { label: child.title, route: child.path };
              })
            }
          />
        );
      })}
    </Box>
  );
}
