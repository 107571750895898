import { Box } from "@mui/material";

export default function MenuAccordion({ open, children, sx }) {
  return (
    <Box
      sx={{
        pl: 1,
        maxHeight: open ? "100vh" : "0px",
        transition: "0.3s",
        overflow: "hidden",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
