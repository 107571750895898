import { Box, Grid, Link, Stack, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import Iconify from "./Iconify";

// TODO: Update with 3jlabs info
export default function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flex: 2,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          backgroundImage: theme.palette.gradients.primary,
          padding: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Iconify
                icon="eva:phone-outline"
                color={theme.palette.secondary.main}
                sx={{
                  mr: 0.5,
                  width: 40,
                  height: 40,
                }}
              />
              <Stack direction="column">
                <Link href="tel:8018018011" sx={{ textDecoration: "none" }}>
                  <Typography
                    sx={{ marginLeft: 2 }}
                    variant="h4"
                    color={theme.palette.grey[200]}
                  >
                    801.801.8011
                  </Typography>
                </Link>
                <Typography
                  sx={{ marginLeft: 2 }}
                  variant="caption"
                  color={theme.palette.grey[400]}
                >
                  Mon. - Fri. 9am - 7pm (MST)
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Iconify
                icon="eva:email-outline"
                color={theme.palette.secondary.main}
                sx={{
                  mr: 0.5,
                  width: 40,
                  height: 40,
                }}
              />
              <Stack direction="column">
                <Link
                  href="mailto:info@3jlabs.net"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{ marginLeft: 2 }}
                    variant="h4"
                    color={theme.palette.grey[200]}
                  >
                    info@3jlabs.net.com
                  </Typography>
                </Link>
                <Typography
                  sx={{ marginLeft: 2 }}
                  variant="caption"
                  color={theme.palette.grey[400]}
                >
                  General questions and partnership
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Typography variant="body2" color={theme.palette.grey[300]}>
            &copy; Copyright {new Date().getFullYear()} 3jlabs
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <Typography variant="body2" color={theme.palette.grey[300]}>
            <Link
              sx={{ textDecoration: "none", color: "inherit" }}
              component={NavLink}
              to="public/privacyPolicy"
            >
              Privacy Policy
            </Link>
            <br />
            <Link
              sx={{ textDecoration: "none", color: "inherit" }}
              component={NavLink}
              to="termsandconditions"
            >
              Terms & Conditions
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
