import { useQueryClient } from "react-query";

const channel = window["BroadcastChannel"] && new BroadcastChannel("general");

export function updateProfileNotify(userProfile) {
  if (channel) {
    channel.postMessage({
      type: "updateProfile",
      userProfile,
    });
  }
}

export default function WindowMessageHandler() {
  const queryClient = useQueryClient();

  if (channel) {
    channel.addEventListener("message", ({ data }) => {
      switch (data.type) {
        case "updateProfile":
          queryClient.setQueryData(["currentUser"], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  email: data.email,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  userName: data.userName,
                }
              : oldData
          );
          break;
        default:
          break;
      }
    });
  }

  return null;
}
