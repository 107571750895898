// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//
import { WebpProvider } from "./components/contexts/WebpContext";
import { initialState, reducer } from "./components/contexts/WebpContext";
import App from "./App";
import WindowMessageHandler from "./components/utility/WindowMessageHandler";
import AuthProvider from "./components/contexts/AuthContext";

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // In production this might want to be true
      staleTime: 1000 * 20,
    },
  },
});

// NOTE: Remove this when we upgrade to react 18
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <WebpProvider initialState={initialState} reducer={reducer}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <HelmetProvider>
          <BrowserRouter>
            <WindowMessageHandler />
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </AuthProvider>
    </QueryClientProvider>
  </WebpProvider>,
  document.getElementById("root")
);
