export const loadFromSessionStorage = (name) => {
  try {
    const serializedState = sessionStorage.getItem(name);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveToSessionStorage = (name, obj) => {
  try {
    const serializedState = JSON.stringify(obj);
    sessionStorage.setItem(name, serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};

export const removeFromSessionStorage = (name) => {
  try {
    sessionStorage.removeItem(name);
  } catch (error) {
    // Ignore write errors.
  }
};
