import { createContext, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import axiosInstance from "src/services/api";
import TokenService from "src/services/token.service";
import parseError from "src/utils/parseError";

export const AuthContext = createContext(null);

// I think we only need accessToken and accessTokenLastRefresh,
// Maybe also need authenticated?
const initialAuthState = {
  authenticated: false,
};

export const accessTokenProvider = (function () {
  let accessToken = null;
  let accessTokenLastRefresh = null;
  function setAccessToken(value) {
    accessToken = value;
  }
  function getAccessToken() {
    return accessToken;
  }
  function setAccessTokenLastRefresh(value) {
    accessTokenLastRefresh = value;
  }
  function getAccessTokenLastRefresh() {
    return accessTokenLastRefresh;
  }
  return {
    setAccessToken,
    getAccessToken,
    setAccessTokenLastRefresh,
    getAccessTokenLastRefresh,
  };
})();

export default function AuthProvider({ children }) {
  const [auth, setAuth] = useState(initialAuthState);
  const queryClient = useQueryClient();

  //Set up the interceptors here
  useEffect(() => {
    const requestInterceptorId = axiosInstance.interceptors.request.use(
      (config) => {
        const accessToken = accessTokenProvider.getAccessToken();
        const accessTokenLastRefresh =
          accessTokenProvider.getAccessTokenLastRefresh();
        if (accessToken) {
          config.headers["Authorization"] = "Bearer " + accessToken;
        }
        config._accessTokenLastRefresh = accessTokenLastRefresh;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptorId = axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        if (!originalConfig.url.startsWith("/auth/") && err.response) {
          // Access Token was expired but is needed for this call
          // attempt to refresh
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              //first we try to get the cross-tab lock
              await navigator.locks.request("refresh_token", async () => {
                // now check if token was refreshed between the time the call failed
                // and the acquisition of the cross-tab lock
                const accessTokenLastRefresh =
                  accessTokenProvider.getAccessTokenLastRefresh();
                if (
                  !originalConfig._accessTokenLastRefresh ||
                  !accessTokenLastRefresh ||
                  originalConfig._accessTokenLastRefresh >=
                    accessTokenLastRefresh
                ) {
                  // try refreshing the token
                  const rs = await axiosInstance.post("/auth/refresh", {
                    refreshToken: TokenService.getRefreshToken(),
                  });
                  const { accessToken, refreshToken } = rs.data;
                  const accessTokenLastRefresh = Date.now();
                  accessTokenProvider.setAccessToken(accessToken);
                  accessTokenProvider.setAccessTokenLastRefresh(
                    accessTokenLastRefresh
                  );
                  TokenService.setRefreshToken(refreshToken);
                }
              });
              originalConfig.headers["Authorization"] =
                accessTokenProvider.getAccessToken();
              originalConfig._accessTokenLastRefresh =
                accessTokenProvider.getAccessTokenLastRefresh();
              return axiosInstance(originalConfig);
            } catch (_error) {
              // Log out user if refreshing access token fails
              setAuth(initialAuthState);
              accessTokenProvider.setAccessToken(null);
              accessTokenProvider.setAccessTokenLastRefresh(null);
              queryClient.invalidateQueries(["currentUser"]);
              return Promise.reject(parseError(_error));
            }
          }
        }
        return Promise.reject(parseError(err));
      }
    );
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptorId);
      axiosInstance.interceptors.response.eject(responseInterceptorId);
    };
  }, [auth, setAuth, queryClient]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => {
  const { auth, setAuth } = useContext(AuthContext);
  return {
    auth,
    setAuth,
  };
};
