import React, { createContext, useContext, useReducer } from "react";

// Create the context
const WebpStateContext = createContext();

// Custom hook to use the global state
export const useWebpState = () => useContext(WebpStateContext);

// Global state provider
export const WebpProvider = ({ reducer, initialState, children }) => (
  <WebpStateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </WebpStateContext.Provider>
);

export const initialState = {
  webpSupport: true,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_WEBP_SUPPORT":
      return {
        ...state,
        webpSupport: action.payload,
      };
    default:
      return state;
  }
};

export const updateWebpSupport = (payload) => ({
  type: "UPDATE_WEBP_SUPPORT",
  payload,
});

export const Initialize = async (dispatch) => {
  const webPcheck = new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("error", () => resolve(false));
    image.addEventListener("load", () => resolve(image.width === 1));
    image.src =
      "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
  }).catch(() => false);

  const browserSupportsWebP = await webPcheck;
  dispatch(updateWebpSupport(browserSupportsWebP));
};
