import { Box, MenuItem, Stack, styled } from "@mui/material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import { Link as RouterLink } from "react-router-dom";
import MenuAccordion from "./MenuAccordian";
import MenuPopover from "./MenuPopover";

export const sharedLinkStyle = (props) => {
  return {
    margin: props.theme.spacing(1.5),
    marginRight: props.theme.spacing(1.5),
    display: "flex",
    textDecoration: "none",
    color: "black",
    padding: 0,
    "&:hover": {
      color: props.theme.palette.primary.dark,
      cursor: "pointer",
    },
    "&.active": {
      color: props.theme.palette.primary.darker,
      borderBottomStyle: "solid",
      borderBottomWidth: "thin",
    },
  };
};

const NavLinkStyled = styled(NavLink)((props) => sharedLinkStyle(props));
const LinkToNowhere = styled("span")((props) => sharedLinkStyle(props));

const StyledLink = ({ to, children, onClick, ...other }) => {
  if (to) {
    return (
      <NavLinkStyled to={to} onClick={onClick} {...other}>
        {children}
      </NavLinkStyled>
    );
  } else {
    return (
      <LinkToNowhere onClick={onClick} {...other}>
        {children}
      </LinkToNowhere>
    );
  }
};

export default function MainMenuLink({ text, to, childLinks = [] }) {
  const [openAnchor, setOpenAnchor] = useState(null);
  const isDesktop = useResponsive("up", "lg");

  // are we a parent link?
  if (childLinks.length > 0) {
    if (isDesktop) {
      return (
        <>
          <StyledLink onClick={(e) => setOpenAnchor(e.currentTarget)}>
            {text}
          </StyledLink>
          <MenuPopover
            onClose={() => setOpenAnchor(null)}
            open={!!openAnchor}
            anchorEl={openAnchor}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            {childLinks.map(({ label, route }) => (
              <MenuItem
                to={route}
                key={label}
                component={RouterLink}
                onClick={() => setOpenAnchor(null)}
              >
                {label}
              </MenuItem>
            ))}
          </MenuPopover>
        </>
      );
    } else {
      return (
        <Box>
          <Stack alignItems="center">
            <StyledLink
              to={to}
              onClick={(e) =>
                setOpenAnchor(openAnchor ? null : e.currentTarget)
              }
              className={openAnchor ? "active" : ""}
            >
              {text}
            </StyledLink>
            <MenuAccordion
              open={!!openAnchor}
              sx={{
                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
                },
              }}
            >
              {childLinks.map(({ label, route }) => (
                <MenuItem
                  to={route}
                  key={label}
                  component={RouterLink}
                  onClick={() => setOpenAnchor(null)}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuAccordion>
          </Stack>
        </Box>
      );
    }
  } else {
    return <StyledLink to={to}>{text}</StyledLink>;
  }
}
