// component
import Iconify from "src/components/Iconify";
import { isAdmin, isLoggedOn, isPartnerUser } from "src/hooks/user";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

// canAccess is a callBack that takes a user object (may be null) and returns true if access is allowed and false otherwise
// If no canAccess callback is provided, it should be assumed that any user can access
const navConfig = [
  {
    title: "Dashboard",
    path: "/portal/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    canAccess: isLoggedOn,
  },
  {
    title: "Accounts",
    path: "/portal/accounts",
    icon: getIcon("ant-design:amazon-circle-filled"),
    canAccess: isLoggedOn,
  },
  {
    title: "Partner",
    path: "/portal/partner",
    icon: getIcon("material-symbols:handshake"),
    canAccess: isPartnerUser,
  },
  {
    title: "Learn",
    path: "/learn",
    icon: getIcon("material-symbols:menu-book"),
  },
  {
    title: "Admin",
    path: "/portal/admin",
    icon: getIcon("dashicons:admin-network"),
    canAccess: isAdmin,
    children: [
      {
        title: "Users",
        path: "/portal/admin/users",
        icon: getIcon("ant-design:user-filled"),
      },
      {
        title: "Notification Tester",
        path: "/portal/admin/notificationTester",
        icon: getIcon("dashicons:admin-network"),
      },
      {
        title: "Articles",
        path: "/portal/admin/articles",
        icon: getIcon("dashicons:admin-network"),
      },
      {
        title: "Topics",
        path: "/portal/admin/topics",
        icon: getIcon("dashicons:admin-network"),
      },
    ],
  },
];

export default navConfig;
