/* eslint-disable no-undef */
const config = {
  apiUrl: process.env.REACT_APP_APIURL || "http://localhost:5000",
  // when built for deployment to AWS PUBLIC_URL is set to the cloudfront url,
  // when run for localstack the contenturl param is set to the localstack s3 bucket endpoint
  contentUrl: `${
    process.env.PUBLIC_URL ||
    process.env.REACT_APP_CONTENTURL ||
    "https://dev.3jlabs.net"
  }/content`,
  mainUrl: process.env.PUBLIC_URL || "http://localhost:3000",
};
export default config;
