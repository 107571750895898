import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "src/components/MenuPopover";
import { useLogout } from "src/hooks/auth";
import { useCurrentUser } from "src/hooks/user";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";
import { useWebpState } from "src/components/contexts/WebpContext";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: "person-outline",
    linkTo: "/portal/dashboard/profile",
  },
  {
    label: "Notification Preferences",
    icon: "person-outline",
    linkTo: "/portal/dashboard/notificationpreferences",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const logout = useLogout();
  const userQuery = useCurrentUser();
  const user = userQuery.data;

  const initials = `${user?.firstName[0]}${user?.lastName[0]}`;

  const [state] = useWebpState();
  const supportsWebp = state.webpSupport;

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    logout.mutate();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          sx={{
            bgcolor: (theme) => theme.palette.primary.dark,
            border: "2px solid",
            borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
          }}
        >
          {!user.primaryImageFileNamePrefix ? (
            initials
          ) : (
            <img
              src={resolvePrimaryImageSrc(
                "user",
                user.id,
                user.primaryImageFileNamePrefix,
                "sm",
                supportsWebp ? "webp" : "jpg"
              )}
              alt={"Profile"}
            />
          )}
        </Avatar>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

AccountPopover.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};
