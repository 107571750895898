import React from "react";
import {
  Tooltip,
  IconButton,
  Popover,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Switch,
  FormLabel,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import PropTypes from "prop-types";

const FilterTooltip = ({ filters, onFilterChange, filterOptions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleFilterChange = (key, value, closeOnChange = false) => {
    // figure out which filter option is changing
    onFilterChange({ ...filters, [key]: value });
    // if filter option has closeOnChange = True  then call handleClose
    if (closeOnChange) {
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title="Filter">
        <IconButton onClick={handleClick}>
          <Iconify icon="mdi:filter-variant" />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2, minWidth: 200 }}>
          {filterOptions.map((option, index) => (
            <Box sx={{ mb: 2 }} key={index}>
              {option.type === "select" && (
                <FormControl fullWidth size="small">
                  <InputLabel>{option.label}</InputLabel>
                  <Select
                    value={filters[option.key] || ""}
                    onChange={(e) =>
                      handleFilterChange(
                        option.key,
                        e.target.value,
                        option.closeOnChange
                      )
                    }
                    label={option.label}
                  >
                    {option.options.map((opt, idx) => (
                      <MenuItem key={idx} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {
                //Right now, "number" has some weird things going on (i.e. changing from uncontrolled
                //to controlled when a number is typed in, etc) and we don't see a common use case for
                //this option that isn't just a numerical search bar, which is unnecessary due to the
                //search functionality in PageActionBar. So we don't recommend using this "number" option
                //at this time.
                option.type === "number" && (
                  <TextField
                    size="small"
                    type="number"
                    label={option.label}
                    value={filters[option.key]}
                    onChange={(e) =>
                      handleFilterChange(
                        option.key,
                        e.target.value,
                        option.closeOnChange
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }

              {option.type === "radio" && (
                <FormControl component="fieldset">
                  <FormLabel>{option.label}</FormLabel>
                  <RadioGroup
                    value={filters[option.key] || ""}
                    onChange={(e) =>
                      handleFilterChange(
                        option.key,
                        e.target.value,
                        option.closeOnChange
                      )
                    }
                  >
                    {option.options.map((opt, idx) => (
                      <FormControlLabel
                        key={idx}
                        value={opt.value}
                        control={<Radio />}
                        label={opt.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}

              {option.type === "checkbox" && (
                <FormControl component="fieldset">
                  <FormLabel>{option.label}</FormLabel>
                  {option.options.map((opt, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          checked={
                            filters[option.key]?.includes(opt.value) || false
                          }
                          onChange={(e) => {
                            const currentValues = filters[option.key] || [];
                            const newValues = e.target.checked
                              ? [...currentValues, opt.value]
                              : currentValues.filter(
                                  (val) => val !== opt.value
                                );
                            handleFilterChange(
                              option.key,
                              newValues,
                              option.closeOnChange
                            );
                          }}
                        />
                      }
                      label={opt.label}
                    />
                  ))}
                </FormControl>
              )}

              {option.type === "switch" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!filters[option.key]}
                      onChange={(e) =>
                        handleFilterChange(
                          option.key,
                          e.target.checked,
                          option.closeOnChange
                        )
                      }
                    />
                  }
                  label={option.label}
                />
              )}
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

FilterTooltip.propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["select", "number", "radio", "checkbox", "switch"])
        .isRequired,
      label: PropTypes.string.isRequired,
      closeOnChange: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default FilterTooltip;
