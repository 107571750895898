import PropTypes from "prop-types";
import * as Yup from "yup";
// material
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormTextField from "src/components/hookform/HookFormTextField";

// ----------------------------------------------------------------------

export default function ForgotPasswordForm({ sendEmail }) {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formMethods = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = async (values) => {
    await sendEmail(values.email);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <HookFormTextField
          name="email"
          label="Email Adress"
          fullWidth
          formMethods={formMethods}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!formState.isValid && formState.submitCount > 0}
        >
          {`Send Reset Email`}
        </LoadingButton>
      </Stack>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  sendEmail: PropTypes.func,
};
