import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import {
  accessTokenProvider,
  useAuth,
} from "src/components/contexts/AuthContext";
import AuthService from "src/services/auth.service";
import { removeFromSessionStorage } from "src/services/sessionStorage";

export const useUpdateAuthContextAndUserQuery = () => {
  const queryClient = useQueryClient();
  const { setAuth } = useAuth();
  return ({
    authenticated,
    pendingEmailVerification,
    accessToken,
    refreshToken,
    user,
  }) => {
    queryClient.setQueryData(["currentUser"], user);
    setAuth({
      authenticated,
    });
    accessTokenProvider.setAccessToken(accessToken);
    accessTokenProvider.setAccessTokenLastRefresh(Date.now());
  };
};

const useClearAuthContextAndInvalidateCurrentUserQuery = () => {
  const queryClient = useQueryClient();
  const { setAuth } = useAuth();
  return () => {
    setAuth({
      authenticated: false,
    });
    removeFromSessionStorage("selectedPartnerId");
    accessTokenProvider.setAccessTokenLastRefresh(null);
    queryClient.setQueryData(["currentUser"], null);
  };
};

export const useLogin = () => {
  const login = useUpdateAuthContextAndUserQuery();
  const logout = useClearAuthContextAndInvalidateCurrentUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.login, {
    onSuccess: (result) => {
      login(result);
    },
    onError: (error) => {
      logout();
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
};

export const useRegister = () => {
  const login = useUpdateAuthContextAndUserQuery();
  const logout = useClearAuthContextAndInvalidateCurrentUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.register, {
    onSuccess: (result) => {
      login(result);
    },
    onError: (error) => {
      logout();
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
};

//Should something happen on error?
export const useLogout = () => {
  const logout = useClearAuthContextAndInvalidateCurrentUserQuery();
  return useMutation(AuthService.logout, {
    onSuccess: () => {
      logout();
    },
  });
};

export const useResendVerification = (email) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(AuthService.resendVerification, {
    onSuccess: () => {
      enqueueSnackbar(`Resent verification message to ${email}`, {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(`unexpected error ${error}`, { variant: "error" });
    },
  });
};

export const useSendPasswordReset = (email) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.sendPasswordReset, {
    onSuccess: (result) => {
      enqueueSnackbar(`If email found, reset link will be sent to ${email}`, {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(`unexpected error ${error}`, { variant: "error" });
    },
  });
};

export const useRefresh = () => {
  const login = useUpdateAuthContextAndUserQuery();
  const logout = useClearAuthContextAndInvalidateCurrentUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.tryRefresh, {
    onSuccess: (result) => {
      if (result) {
        login(result);
      } else {
        logout();
      }
    },
    onError: (error) => {
      logout();
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
};

export const usePasswordResetWithToken = () => {
  const login = useUpdateAuthContextAndUserQuery();
  const logout = useClearAuthContextAndInvalidateCurrentUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.passwordResetWithToken, {
    onSuccess: (result) => {
      login(result);
    },
    onError: (error) => {
      logout();
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
};
