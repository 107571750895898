import PropTypes from "prop-types";
import { useEffect } from "react";

import Spinner from "src/components/branded/Spinner";
import TokenService from "src/services/token.service";
import { useAuth } from "../contexts/AuthContext";
import { useRefresh } from "src/hooks/auth";

// ----------------------------------------------------------------------

export default function AutoLogon({ children }) {
  const { auth } = useAuth();
  const authenticated = auth.authenticated;
  const refresh = useRefresh();
  const refreshTokenPresent = !!TokenService.getRefreshToken();

  // TODO: need some listener here that will listen for localStorage update to "refreshToken"
  //       when it becomes empty (or maybe listen for logout post message event) then we should trigger a
  //       logout

  useEffect(() => {
    if (!authenticated && refreshTokenPresent) {
      const getLock = async () => {
        await navigator.locks.request("refresh_token", async () => {
          refresh.mutate();
        });
      };
      getLock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authenticated && refreshTokenPresent) {
    return <Spinner />;
  } else {
    return children;
  }
}

AutoLogon.propTypes = {
  children: PropTypes.any,
};
