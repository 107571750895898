import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Stack,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { useAuth } from "../../components/contexts/AuthContext";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import LoginForm from "src/features/authentication/LoginForm";
import {
  useLogin,
  useLogout,
  useResendVerification,
  useSendPasswordReset,
} from "src/hooks/auth";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/Iconify";
import RegisterForm from "src/features/authentication/RegisterForm";
import ForgotPasswordForm from "src/features/authentication/ForgotPasswordForm";

export default function AuthenticationModal({
  open,
  onClose,
  initialScreenToShow = "login",
  onAuthenticated,
  children,
}) {
  const { auth } = useAuth();
  const authenticated = auth.authenticated;
  const [screenToShow, setScreenToShow] = useState(initialScreenToShow);

  const login = useLogin();
  const logout = useLogout();

  const [attemptedEmail, setAttemptedEmail] = useState(null);
  const resendVerification = useResendVerification(attemptedEmail);

  const [loading, setLoading] = useState(false);

  const handleResendVerification = async () => {
    setLoading(true);
    await resendVerification.mutateAsync({ email: attemptedEmail });
    setLoading(false);
  };

  const sendPasswordReset = useSendPasswordReset();
  const handleSendPasswordReset = async (email) => {
    await sendPasswordReset.mutateAsync(email);
  };

  const handleBackToLogon = () => {
    setAttemptedEmail(null);
    setScreenToShow("verifyEmail");
    logout.mutate();
  };

  const hasChildren = children != null;

  // This effect only runs when the modal opens and the user is authenticated
  useEffect(() => {
    if (open && authenticated && !hasChildren) {
      onAuthenticated();
      onClose();
    }
  }, [open, authenticated, onAuthenticated, onClose, hasChildren]);

  if (open && authenticated) {
    return children || null;
  }

  return (
    <Dialog fullWidth open={open && !authenticated} onClose={onClose}>
      <DialogTitle sx={{ pr: 5 }}>
        {screenToShow === "login"
          ? "Welcome Back!"
          : screenToShow === "register"
          ? "Get Started!"
          : screenToShow === "verifyEmail"
          ? "Verify Your Email"
          : ""}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="eva:close-outline" width={20} height={20} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {screenToShow === "login" ? (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Sign in with your details below.
              </Typography>
              <LoginForm
                compressed
                tryLogin={async (email, password) => {
                  setAttemptedEmail(email);
                  await login.mutateAsync(
                    { email, password },
                    {
                      onSuccess: (data) => {
                        if (data?.authenticated) {
                          onAuthenticated();
                        } else if (data?.pendingEmailVerification) {
                          setScreenToShow("verifyEmail");
                        } else {
                          // Result was success, but not authenticated, but not because email was unverified
                          // I don't think this case should ever happen, but we could show a screen or snackbar
                          // showing that some kind of unexpected error occured.
                        }
                      },
                    }
                  );
                }}
                forgotPasswordRedirectOverride={() =>
                  setScreenToShow("forgotPassword")
                }
              />
              <Typography variant="caption" sx={{ mt: 3, textAlign: "center" }}>
                Don't have an account?{" "}
                <Link
                  variant="caption"
                  component={Button}
                  onClick={() => setScreenToShow("register")}
                  sx={{ verticalAlign: "unset" }}
                >
                  Create an account
                </Link>
              </Typography>
            </>
          ) : screenToShow === "register" ? (
            <>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary">
                Get started, register below.
              </Typography>
              <RegisterForm
                compressed
                disableSuccessRedirect
                onSuccess={(data) => {
                  setAttemptedEmail(data?.user?.email);
                  if (data?.authenticated) {
                    onAuthenticated();
                  } else if (data?.pendingEmailVerification) {
                    setScreenToShow("verifyEmail");
                  } else {
                    // Result was success, but not authenticated, but not because email was unverified
                    // I don't think this case should ever happen, but we could show a screen or snackbar
                    // showing that some kind of unexpected error occured.
                  }
                }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 3, textAlign: "center" }}
              >
                Already have an account?{" "}
                <Link
                  component="button"
                  variant="caption"
                  sx={{ verticalAlign: "unset" }}
                  onClick={() => setScreenToShow("login")}
                >
                  Login
                </Link>
              </Typography>

              <Typography
                variant="caption"
                align="center"
                sx={{ color: "text.secondary", mt: 2, mb: 1 }}
              >
                By registering, I agree to 3jlabs'&nbsp;
                <br />
                <Link
                  onClick={onClose}
                  component={RouterLink}
                  to="/termsandconditions"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  onClick={onClose}
                  component={RouterLink}
                  to="/public/privacypolicy"
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            </>
          ) : screenToShow === "verifyEmail" ? (
            <>
              <Typography variant="h4" gutterBottom>
                Verify your email to logon.
              </Typography>

              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                To finish your registration please verify you are the owner of
                the email address used to register.
              </Typography>

              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                Check your email at {attemptedEmail} and follow the
                instructions.
              </Typography>

              <LoadingButton
                onClick={async () => await handleResendVerification()}
                variant="contained"
                loading={loading}
              >
                Resend Verification Email
              </LoadingButton>
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                <Link
                  variant="subtitle2"
                  component="button"
                  onClick={handleBackToLogon}
                >
                  Back to logon.
                </Link>
              </Typography>
            </>
          ) : screenToShow === "forgotPassword" ? (
            <>
              <Typography variant="h4" gutterBottom>
                Forgot your password?
              </Typography>

              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                Enter your email address below and we&apos;ll send you reset
                link.
              </Typography>

              <ForgotPasswordForm
                sendEmail={async (email) =>
                  await handleSendPasswordReset(email)
                }
              />

              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                <Link
                  variant="subtitle2"
                  onClick={() => setScreenToShow("login")}
                >
                  Back to login
                </Link>
              </Typography>
            </>
          ) : (
            <Typography>How did you get here?</Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
