import { useQuery } from "react-query";
import api from "src/services/api";

// API methods - not exposed outside this class
const getRoles = async (isPartner = false) => {
  const { data } = await api.get(`/role?isPartner=${isPartner}`);
  return data;
};

// Custom hooks

export const useRoleList = () => {
  return useQuery(["roleList"], () => getRoles(false));
};

export const usePartnerRoleList = () => {
  return useQuery(["partnerRoleList"], () => getRoles(true));
};
