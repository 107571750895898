import React from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

// non lazy loaded pages
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/Page404";
import { Suspense } from "react";
import Spinner from "./components/branded/Spinner";
import Users from "./pages/admin/Users";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";

// lazy loaded components for code splitting based on routes
const Login = React.lazy(() => import("./pages/login/Login"));
const Register = React.lazy(() => import("./pages/register/Register"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const Verify = React.lazy(() => import("./pages/Verify"));
const ForgotPassword = React.lazy(() =>
  import("./pages/forgotPassword/ForgotPassword")
);
const PassswordReset = React.lazy(() => import("./pages/PasswordReset"));
const Profile = React.lazy(() => import("./pages/account/Profile"));
const NotificationPreferences = React.lazy(() =>
  import("./pages/account/NotificationPreferences")
);
const Notifications = React.lazy(() => import("./pages/account/Notifications"));
const VerifyNewEmail = React.lazy(() => import("./pages/VerifyNewEmail"));
const PartnerList = React.lazy(() => import("./pages/partner/Partners"));
const PartnerDashboard = React.lazy(() => import("./pages/partner/Dashboard"));
const AcceptInvite = React.lazy(() => import("./pages/AcceptInvite"));
const NotificationTester = React.lazy(() =>
  import("./pages/admin/NotificationTester")
);
const AdminArticle = React.lazy(() => import("./pages/articles/AdminArticle"));
const AdminArticleList = React.lazy(() =>
  import("./pages/articles/AdminArticleList")
);
const EditArticle = React.lazy(() => import("./pages/articles/EditArticle"));
const UserArticle = React.lazy(() => import("./pages/articles/UserArticle"));
const UserArticleList = React.lazy(() =>
  import("./pages/articles/UserArticleList")
);
const TopicArticleList = React.lazy(() =>
  import("./pages/articles/TopicArticleList")
);
const Topic = React.lazy(() => import("./pages/topics/Topic"));
const TopicList = React.lazy(() => import("./pages/topics/TopicList"));
const EditTopic = React.lazy(() => import("./pages/topics/EditTopic"));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/portal",
      element: (
        <Suspense fallback={<Spinner />}>
          <Layout mode="authenticated" />
        </Suspense>
      ),
      children: [
        { path: "", element: <Navigate to="/portal/dashboard" replace /> },
        {
          path: "dashboard",
          element: <Outlet />,
          children: [
            { path: "profile", element: <Profile /> },
            {
              path: "notificationPreferences",
              element: <NotificationPreferences />,
            },
            { path: "notifications", element: <Notifications /> },
            { path: "verifyNewEmail", element: <VerifyNewEmail /> },
            { path: "", element: <Dashboard /> },
            {
              path: "products",
              element: <Outlet />,
              children: [],
            },
          ],
        },

        {
          path: "accounts",
          element: <Outlet />,
          children: [],
        },
        {
          path: "partner",
          children: [
            { path: "", element: <PartnerList /> },
            { path: ":partnerId", element: <PartnerDashboard /> },
          ],
        },
        {
          path: "admin",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Navigate to="/portal/admin/users" replace />,
            },
            { path: "users", element: <Users /> },
            { path: "notificationTester", element: <NotificationTester /> },
            {
              path: "articles",
              element: <Outlet />,
              children: [
                { path: "", element: <AdminArticleList /> },
                { path: ":articleSlug", element: <AdminArticle /> },
                {
                  path: ":articleSlug/edit",
                  element: <EditArticle />,
                },
              ],
            },
            {
              path: "topics",
              element: <Outlet />,
              children: [
                { path: "", element: <TopicList /> },
                { path: ":topicId", element: <Topic /> },
                {
                  path: ":topicId/edit",
                  element: <EditTopic />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "learn",
      element: (
        <Suspense fallback={<Spinner />}>
          <Layout mode="public" />
        </Suspense>
      ),
      children: [
        { path: "", element: <UserArticleList /> },
        { path: ":topicSlug/:articleSlug", element: <UserArticle /> },
        { path: ":topicSlug", element: <TopicArticleList /> },
      ],
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<Spinner />}>
          <Layout mode="public" />
        </Suspense>
      ),
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "public",
          element: <Outlet />,
          children: [{ path: "privacyPolicy", element: <PrivacyPolicy /> }],
        },
      ],
    },
    {
      path: "/invite",
      element: (
        <Suspense fallback={<Spinner fixedPosition={true} />}>
          <AcceptInvite />
        </Suspense>
      ),
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    {
      path: "/verify",
      element: (
        <Suspense fallback={<Spinner fixedPosition={true} />}>
          <Verify />
        </Suspense>
      ),
    },
    {
      path: "/",
      element: <Layout mode={"unauthenticated"} />,
      children: [
        { path: "/", element: <Navigate to="/portal/dashboard" replace /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "passwordReset", element: <PassswordReset /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
}
