import * as Yup from "yup";
import { useState } from "react";
import { Navigate } from "react-router-dom";
// material
import {
  Stack,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "src/components/Iconify";
// action
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormTextField from "src/components/hookform/HookFormTextField";
import { useAuth } from "src/components/contexts/AuthContext";
import { useRegister } from "src/hooks/auth";

// ----------------------------------------------------------------------

export default function RegisterForm({
  defaultEmail,
  emailReadOnly = false,
  disableSuccessRedirect = false,
  onSuccess,
  inviteToken,
}) {
  const compressed = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth } = useAuth();
  const isLoggedOn = auth.authenticated;
  const register = useRegister();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formMethods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: defaultEmail || "",
      password: "",
      inviteToken,
    },
    resolver: yupResolver(RegisterSchema),
  });

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = async (values) => {
    await register.mutateAsync(
      {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        inviteToken: values.inviteToken,
      },
      { onSuccess: onSuccess }
    );
  };

  if (isLoggedOn && !disableSuccessRedirect) {
    return <Navigate to="/" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={compressed ? 1 : 3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={compressed ? 1 : 2}
        >
          <HookFormTextField
            name="firstName"
            label="First name"
            fullWidth
            formMethods={formMethods}
          />

          <HookFormTextField
            name="lastName"
            label="Last name"
            fullWidth
            formMethods={formMethods}
          />
        </Stack>

        <HookFormTextField
          name="email"
          label="Email Adress"
          fullWidth
          formMethods={formMethods}
          inputProps={{ readOnly: emailReadOnly }}
        />

        <HookFormTextField
          name="password"
          label="Password"
          fullWidth
          formMethods={formMethods}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size={compressed ? "medium" : "large"}
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!formState.isValid && formState.submitCount > 0}
        >
          Register
        </LoadingButton>
      </Stack>
    </form>
  );
}
