import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// @mui
import { Button, Typography, Container, Box } from "@mui/material";
// components
import Page from "src/components/Page";

export default function Error({ clearError }) {
  const navigate = useNavigate();
  return (
    <Page title="Unexpected Error">
      <Container>
        <Box
          sx={{
            maxWidth: 768,
            margin: "auto",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: 12,
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" paragraph>
            Sorry, an unexpected error has occurred!
          </Typography>
          <Typography paragraph>Something unexpected occurred.</Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              navigate("/");
              clearError();
            }}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

Error.propTypes = {
  clearError: PropTypes.func,
};
