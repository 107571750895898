import { SnackbarProvider } from "notistack";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/utility/ScrollToTop";
import AutoLogon from "./components/utility/AutoLogon";
import ErrorBoundary from "./components/utility/ErrorBoundary";
import { Box } from "@mui/material";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <ScrollToTop />
        <AutoLogon>
          <Box
            sx={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </Box>
        </AutoLogon>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
