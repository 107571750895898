import React from "react";
import PropTypes from "prop-types";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import FilterTooltip from "./FilterTooltip";
import Iconify from "./Iconify";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const ListActionBar = ({
  actionButtons,
  onSearch,
  filters,
  onFilterChange,
  filterOptions,
  searchPlaceholder,
}) => {
  return (
    <Stack
      className="list-action-bar"
      direction={"row"}
      alignItems="center"
      justifyContent="space-between"
      padding={2}
      sx={{ width: "100%" }}
    >
      <Stack
        className="action-buttons"
        direction={"row"}
        justifyContent={"flex-start"}
        gap={1}
      >
        {actionButtons && actionButtons.length > 0 && (
          <>
            {actionButtons.map((button, index) => (
              <Button
                key={index}
                variant={button.variant || "contained"}
                color={button.color || "primary"}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}
          </>
        )}
      </Stack>
      <Stack
        className="search-filter"
        direction={"row"}
        alignItems="center"
        justifyContent={"flex-end"}
        gap={1}
      >
        {onSearch && (
          <TextField
            variant="outlined"
            size="small"
            type="search"
            placeholder={searchPlaceholder || "Search..."}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:magnify" />
                </InputAdornment>
              ),
            }}
            onChange={debounce((e) => onSearch(e.target.value))}
          />
        )}
        {filterOptions && filterOptions.length > 0 && (
          <FilterTooltip
            filters={filters}
            onFilterChange={onFilterChange}
            filterOptions={filterOptions}
          />
        )}
      </Stack>
    </Stack>
  );
};

ListActionBar.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(["text", "contained", "outlined"]),
      color: PropTypes.oneOf([
        "default",
        "primary",
        "secondary",
        "error",
        "info",
        "success",
        "warning",
      ]),
      onClick: PropTypes.func.isRequired,
    })
  ),
  onSearch: PropTypes.func,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["select", "number", "radio", "checkbox", "switch"])
        .isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  searchPlaceholder: PropTypes.string,
};

ListActionBar.defaultProps = {
  actionButtons: [],
  onSearch: null,
  filters: {},
  onFilterChange: null,
  filterOptions: [],
  searchPlaceholder: "...",
};

export default ListActionBar;
