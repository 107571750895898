import React, { useEffect } from "react";
import { AppBar, Box, useTheme } from "@mui/material";

export default function TopBanner({
  bannerHeight = 60,
  isScrolled,
  setIsScrolled,
  onClick,
  children,
}) {
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > bannerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [bannerHeight, isScrolled, setIsScrolled]);

  const theme = useTheme();

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        cursor: onClick && "pointer",
      }}
      onClick={onClick}
    >
      <Box
        component={"div"}
        sx={{
          height: !isScrolled ? bannerHeight : 0,
          width: "100%",
          transition: "all 0.3s ease",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "4px",
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <Box
            gap={5}
            sx={{
              height: `${bannerHeight - 8}px`,
              border: "1px solid white",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px 8px",
              backgroundColor: "transparent",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
}
