import PropTypes from "prop-types";
import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import { Link, Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/Iconify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import HookFormTextField from "src/components/hookform/HookFormTextField";
// component

// ----------------------------------------------------------------------

export default function LoginForm({
  defaultEmail,
  emailReadOnly = false,
  tryLogin,
  forgotPasswordRedirectOverride,
}) {
  // local state for component
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formMethods = useForm({
    defaultValues: { email: defaultEmail || "", password: "" },
    resolver: yupResolver(LoginSchema),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = async (values) => {
    await tryLogin(values.email, values.password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <HookFormTextField
          name="email"
          label="Email Adress"
          fullWidth
          formMethods={formMethods}
          readOnly={emailReadOnly}
        />

        <HookFormTextField
          name="password"
          label="Password"
          fullWidth
          formMethods={formMethods}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="right"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <div></div>
        {/* TODO: ad a "To" page with a form for sending password reset email */}
        {forgotPasswordRedirectOverride ? (
          <Link
            variant="subtitle2"
            underline="hover"
            onClick={() => forgotPasswordRedirectOverride()}
          >
            Forgot password?
          </Link>
        ) : (
          <Link
            component={RouterLink}
            variant="subtitle2"
            to="/forgotPassword"
            underline="hover"
          >
            Forgot password?
          </Link>
        )}
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={!formState.isValid && formState.submitCount > 0}
      >
        {`Login`}
      </LoadingButton>
    </form>
  );
}

LoginForm.propTypes = {
  defaultEmail: PropTypes.string,
  emailReadOnly: PropTypes.bool,
  tryLogin: PropTypes.func,
};
