import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// material
import { Box, Drawer, Typography, Stack } from "@mui/material";
// hooks
import useResponsive from "src/hooks/useResponsive";
// components
import Logo from "src/components/branded/Logo";
import Scrollbar from "src/components/Scrollbar";
import NavSection from "src/components/NavSection";
//
import navConfig from "./NavConfig";
import { useSelectedPartner } from "src/components/contexts/SelectedPartnerContext";
import { usePartner } from "src/hooks/partner";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = "280px";

// ----------------------------------------------------------------------

PortalSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function PortalSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const { selectedPartnerId } = useSelectedPartner();
  const partnerQuery = usePartner(selectedPartnerId);
  const selectedPartner = partnerQuery.data;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {selectedPartner && (
        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
          <Stack
            alignItems="center"
            spacing={3}
            sx={{ pt: 5, borderRadius: 2, position: "relative" }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography gutterBottom variant="h6">
                Current Selected Partner:
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {selectedPartner?.name}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up("lg")]: {
          flexShrink: 0,
          width: DRAWER_WIDTH,
        },
      })}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
