import config from "./config";
// ----------------------------------------------------------------------
export function resolvePrimaryImageSrc(
  namespace,
  objectId,
  primaryImageFileNamePrefix,
  size,
  type
) {
  return `${config.contentUrl}/${namespace}/${objectId}/primary/${primaryImageFileNamePrefix}_${size}.${type}`;
}

export function getImageObject(
  namespace,
  objectId,
  imageFileNamePrefix,
  isPrimary
) {
  const url = `${config.contentUrl}/${namespace}/${objectId}/${
    isPrimary ? "primary" : "gallery"
  }/${imageFileNamePrefix}_`;
  return {
    webp: {
      sm: `${url}sm.webp`,
      md: `${url}md.webp`,
      lg: `${url}lg.webp`,
    },
    jpg: {
      sm: `${url}sm.jpg`,
      md: `${url}md.jpg`,
      lg: `${url}lg.jpg`,
    },
    prefix: imageFileNamePrefix,
  };
}
