import PropTypes from "prop-types";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
// components
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import MenuPopover from "src/components/MenuPopover";
import {
  useUserNotificationList,
  useUpdateUserNotification,
  useUpdateAllUserNotifications,
  useCurrentUser,
} from "src/hooks/user";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const anchorRef = useRef(null);

  //Updated to import notification list

  //react query hooks
  const userQuery = useCurrentUser();
  const user = userQuery.data;
  const notificationsQuery = useUserNotificationList(user?.id, 0, 5);
  const notifications = notificationsQuery.data?.entries || [];
  const updateAllUserNotifications = useUpdateAllUserNotifications();

  // const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const totalUnRead = notifications.filter(
    (item) => item.readStatus === false
  ).length;

  const handleMarkAllAsRead = () => {
    updateAllUserNotifications.refetch();
  };

  //previously existing stuff
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const navigate = useNavigate();
  const handleViewAllNotifications = () => {
    // Navigate to the Notifications page
    navigate("/portal/dashboard/notifications");
    handleClose();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, totalUnRead).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(totalUnRead, 5).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleViewAllNotifications}>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

//TODO: adjust these based off actual props
NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.number,
    readStatus: PropTypes.bool,
    notificationType: PropTypes.string,
    content: PropTypes.string,
  }),
};

function NotificationItem({ notification }) {
  const { icon, title } = renderContent(notification);
  const updateUserNotification = useUpdateUserNotification();

  const handleMarkAsRead = () => {
    updateUserNotification.mutate(notification.id);
  };

  return (
    <ListItemButton
      onClick={(event) => {
        handleMarkAsRead();
        event.stopPropagation();
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.readStatus && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemIcon>
        <Iconify icon={icon} sx={{ mr: 0.5, width: 24, height: 24 }} />
      </ListItemIcon>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            1 hour ago
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.notificationType}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.content}
      </Typography>
    </Typography>
  );

  if (notification.notificationType === "GettingStarted") {
    return {
      icon: "eva:info-fill",
      title,
    };
  }
  if (notification.type === "message") {
    return {
      icon: "eva:email-fill",
      title,
    };
  }
  /* add additional types/icon assignments here  */
  return {
    icon: "eva:question-mark-circle-fill",
    title,
  };
}
