import { useNavigate } from "react-router-dom";
// @mui
import { Button, Typography, Container } from "@mui/material";
// components
import Page from "src/components/Page";
import { useState } from "react";
import AuthenticationModal from "src/features/authentication/AuthenticationModal";

export default function Home() {
  const navigate = useNavigate();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const onModalClose = () => {
    setAuthModalOpen(false);
  };
  return (
    <Page title="HomePage">
      <Container>
        <AuthenticationModal
          open={authModalOpen}
          onClose={onModalClose}
          onAuthenticated={() => {
            navigate("/portal/dashboard");
            onModalClose();
          }}
        />
        <Typography variant="h3" paragraph>
          Home page!
        </Typography>

        <Typography variant="body2" paragraph>
          If you're logged on you should have some way to get to the full menu?
          Or should the menu just show up?
        </Typography>

        <Button
          size="large"
          variant="contained"
          onClick={() => setAuthModalOpen(true)}
        >
          Try to go to portal (won't work if not logged on)
        </Button>
      </Container>
    </Page>
  );
}
